import * as React from 'react'

import { Link } from '@mui/material'

interface Question {
  title: string | React.ReactElement
  body: string | React.ReactElement
}

interface faqSection {
  title: string
  section: string
  questions: Question[]
}

export const faqData: faqSection[] = [
  {
    title: 'Depósitos y Retiros',
    section: 'withdrawals-and-deposits',
    questions: [
      {
        title: '¿Qué criptomonedas soporta la plataforma?',
        body: 'En este momento aceptamos custodia y rentabilidad en Bitcoin, Ethereum, ' +
              'Cardano, Polkadot, Solana, USDC y USDT.',
      },
      {
        title: '¿Cuáles son los límites de depósitos y retiros?',
        body: 'No hay límites de depósitos ni retiros.',
      },
      {
        title: '¿Cuánto suele demorarse en acreditar mi depósito en la plataforma?',
        body: (
          'Las transferencias en criptomonedas suelen demorar entre 5 minutos y 1 hora dependiendo del cripto activo y'
          + ' la red de transferencia.'
        ),
      },
      {
        title: '¿Cuánto es el fee de depósito?',
        body: 'No cobramos ningún fee de depósito.',
      },
      {
        title: '¿Cuánto es el fee de retiro?',
        body: (
          'No cobramos ningún fee de transferencias internas. Para retirar tus criptomonedas a otro exchange o wallet,'
          + ' existe un fee de retiro de US$2,00 + el gas fee de la red que utilices.'
        ),
      },
      {
        title: '¿Cuánto suele demorarse en realizar el retiro?',
        body: (
          'Todos los retiros se procesan de forma instantánea. Existen excepciones para grandes retiros (Usualmente'
          + ' mayores a US$25.000) donde procesamos el retiro de forma manual junto al usuario para otorgarle mayor'
          + ' seguridad. Este tipo de retiros se verifican manualmente en menos de 24 horas.'
        ),
      },
      {
        title: '¿Por qué necesitan verificar las grandes transacciones?',
        body: (
          'Porque nos tomamos la seguridad muy en serio y creemos que esta medida otorga una capa extra de seguridad'
          + ' contra gente mal intencionada. Esta protección extra la brindamos mediante una multifirma digital.'
        ),
      },
      {
        title: '¿Qué red se usa para las transferencias de criptomonedas?',
        body: (
          'Actualmente utilizamos la red de bitcoin, ERC-20, y próximamente incorporaremos las redes de Binance y'
          + ' Polygon.'
        ),
      },
    ],
  },
  {
    title: 'Recompensas',
    section: 'interest-rates',
    questions: [
      {
        title: '¿Cuándo comenzaré a acumular recompensas con mis criptomonedas?',
        body: 'Comenzarás a acumular recompensas 24 horas después de tu depósito.',
      },
      {
        title: '¿Qué recompensas puedo acumular con mis criptomonedas?',
        body: (
          <React.Fragment>
            Las recompensas se depositan semanalmente en la misma criptomoneda. Puedes
            ver los rendimientos de cada criptomoneda en la <Link href='/about/#apy'>
              página donde explicamos qué es Sugarblock
            </Link>.
          </React.Fragment>
        ),
      },
      {
        title: '¿Cada cuánto recibo recompensas por mis criptos?',
        body: 'Las recompensas se entregan semanalmente en tu cuenta Sugarblock.',
      },
      {
        title: '¿Dónde recibo mis recompensas?',
        body: 'Las recompensas son depositadas directamente en tu cuenta Sugarblock.',
      },
    ],
  },
  {
    title: 'Sugarblock & Producto',
    section: 'withdrawals-and-deposits',
    questions: [
      {
        title: '¿Están seguras mis criptomonedas?',
        body: (
          'Nuestra prioridad es la seguridad y es por eso que tus criptomonedas son custodiadas en la bóveda de nuestro'
          + ' partner, Ledger. Los activos dentro de Ledger Vault tienen una capa extra de seguridad, y por encima de'
          + ' eso un seguro contra los hackers de hasta 150 millones de dólares.'
        ),
      },
      {
        title: '¿En qué consiste la capa extra de seguridad?',
        body: (
          'Ledger Vault proporciona un sistema de recuperación independiente de claves privadas, proporcionando un'
          + ' respaldo extra en caso de extravío o hackeo de claves. Esta capa también protege cualquier transferencia'
          + ' mayor a US$25.000 mediante un sistema de multifirma digital, previniendo cualquier robo o hackeo a las'
          + ' cuentas de nuestros usuarios.'
        ),
      },
      {
        title: '¿Puedo comprar criptomonedas en Sugarblock?',
        body: (
          'Pronto podrás comprar mediante tarjeta de crédito, débito o transferencia bancaria. También'
          + ' podrás comprar criptomonedas con tus stablecoins o bien hacer swaps entre tus cripto activos.'
        ),
      },
      {
        title: '¿En qué monedas reciben transferencias?',
        body: (
          'Próximamente podrás transferir dólares estadounidenses (USD) y/o pesos chilenos (CLP) a una cuenta bancaria'
          + ' en Chile o en Estados Unidos.'
        ),
      },
      {
        title: '¿Cómo heredo mis criptomonedas?',
        body: (
          'Estamos desarrollando esta funcionalidad y gradualmente la habilitaremos en las distintas legislaciones.'
          + ' Pronto podrás acceder a esta configuración en tu plataforma Sugarblock.'
        ),
      },
    ],
  },
]

export default faqData
